




























































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';

@Component({
    components: { }
})
export default class UserProfile extends ListBase {
    selected: number = null;
    selectedChild: number = null; 
    options: Array<any> = [
        {
            id: 3, title: '&iquest;Aprueba el Balance General del ejercicio econ&oacute;mico correspondiente del a&ntilde;o 2020?', children: null
        },
        {
            id: 4, title: '&iquest;Aprueba la modificaci&oacute;n de los art. 39 y 64 de los estatutos de la Instituci&oacute;n?',
            children: [
                { id: 1, title: '1. &iquest;Aprueba la adici&oacute;n al art. 39 de los estatutos, la realizaci&oacute;n excepcional de Asamblea Virtual?' },
                { id: 2, title: '2. &iquest;Aprueba la modificaci&oacute;n del inciso e) del art. 64 del Estatuto de la Instituci&oacute;n, a efecto de hacer viable la entrega anticipada excepcional por &uacute;nica vez y con efecto cancelatorio, no mayor del 25%, del total de los Aportes efectuados al Fondo Mutual por cada miembro de la Orden?' }
            ]
        }
    ];

    get loading() {
        return this.$store.state.lawyer.loading;
    }
    get votes() {
        let result = [];
        this.$store.state.lawyer.votes.forEach(item => {
            item.response = Util.getTypeValueById(item.response_id).name;
            result[item.option] = item;
        });
        return result;
    }
    get user() {
        return this.$store.state.session.user;
    }
    get disabled() {
        if (this.selected == null) return true;
        let item = this.options[this.selected];
        if (item.children != null && this.selectedChild == null)
            return true;
        return false;
    }

    showResults() {
        let item = this.options[this.selected];
        if (item.children != null && this.selectedChild != null)
            item = item.children[this.selectedChild];
        this.$store.commit('vote/setOption', item);
        this.$store.commit('vote/setList', []);
        Util.toRoute(this, 'results');
    }

    async mounted() {
        let result = await this.$store.dispatch({
            type: 'lawyer/getVotes',
            data: { id: Util.abp.session.userId }
            //data: { id: 10142 }                
        });
    }
}